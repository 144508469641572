const DELETION_KEYS_CODES: Array<string> = [
  'Backspace', 'Delete'
];

const NAVIGATION_KEYS_CODES: Array<string> = [
  'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown'
];

const KEYBOARD_NUMERIC_KEYS_CODES: Array<string> = [
  'Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9'
];

const KEYPAD_NUMERIC_KEYS_CODES: Array<string> = [
  'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'
];

const DECIMAL_KEYS_CODES: Array<string> = [
  'Period', 'NumpadDecimal'
];

const isKeyAllowedForNumericInput = (code: string): boolean => {
  return DELETION_KEYS_CODES.includes(code) ||
    NAVIGATION_KEYS_CODES.includes(code) ||
    KEYBOARD_NUMERIC_KEYS_CODES.includes(code) ||
    KEYPAD_NUMERIC_KEYS_CODES.includes(code) ||
    DECIMAL_KEYS_CODES.includes(code);
};

export const disableNonNumericKeys = (event: KeyboardEvent): void => {
  if (!isKeyAllowedForNumericInput(event.code)) {
    event.preventDefault();
  }
};
