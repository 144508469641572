<template>
  <component :is="tag" data-testid="link-component" class="ui-cursor-pointer">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { LinkProps } from "./types";

const { tag } = withDefaults(defineProps<LinkProps>(), {
  tag: "a",
});
</script>
